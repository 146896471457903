<template>
  <div class="photo-viewer">
    <v-btn
      class="photo-viewer__close"
      icon
      text
      color="white"
      @click="$emit('close')"
    >
      <v-icon small>
        mdi-close
      </v-icon>
    </v-btn>
    <img :src="imageUrl" class="photo-viewer__img" />
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    imageType: {
      type: String,
      required: true
    }
  },
  setup() {}
};
</script>

<style lang="scss">
.photo-viewer {
  position: relative;
  background-color: #dbdbdb;

  @media (max-width: 600px) {
    display: flex;
    align-items: center;
  }

  &__close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 3;
    background: rgba(0, 0, 0, 0.4);
  }

  &__img {
    min-width: 500px;
    min-height: 500px;
    max-height: 90vh;
    max-width: 90vw;

    @media (max-width: 600px) {
      max-width: 100%;
      max-height: 100%;

      min-width: auto;
      min-height: auto;
      width: 100%;
    }
  }
}
</style>
